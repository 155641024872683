// Dependencies - Vendor
import { type Component, h, type VNode } from 'vue';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';

// Dependencies - Framework
import { handleError, trackPageViewEvent } from '@/globals';
import { useWorkbenchStore } from '@/stores/workbenchStore';

// Dependencies - Component
import UnavailableMask from '@/components/UnavailableMask.vue'; // Commonly required if internet connection is lost, so cannot be dynamically loaded.

// Constants
const TIMEOUT_DELAY = 200;

// Router - Routes
const routes: RouteRecordRaw = {
    path: '/',
    component: (): Component => import('@/views/WorkbenchLayout.vue'),
    children: [
        { path: '', name: 'default', component: (): Component => import('@/views/workflow/dashboard/WorkflowDashboard.vue') },
        {
            path: 'administrator',
            name: 'administrator',
            children: [{ path: '', name: 'administratorDefault', component: (): Component => import('@/views/administrator/dashboard/AdministratorDashboard.vue') }]
        },
        { path: 'partner', name: 'partner', children: [{ path: '', name: 'partnerDefault', component: (): Component => import('@/views/partner/dashboard/PartnerDashboard.vue') }] },
        {
            path: 'workflow',
            name: 'workflow',
            children: [
                {
                    path: '',
                    name: 'workflowDefault',
                    component: (): Component => import('@/views/workflow/dashboard/WorkflowDashboard.vue')
                },
                {
                    path: 'establishDataViews',
                    children: [
                        {
                            path: '',
                            component: (): Component =>
                                new Promise((resolve): void => {
                                    setTimeout((): void => {
                                        resolve(
                                            import('@/views/workflow/establishDataViews/EstablishDataViews.vue').then((component): Component => component).catch((error): VNode => h(UnavailableMask, { error }))
                                        );
                                    }, 0); // TODO: Remove timeout, used for testing.
                                })
                        }
                        // { path: 'steps/:dataViewId?', component: (): Component => import('@/views/workflow/establishDataViews/EstablishDataViewsSteps.vue') }
                    ]
                },
                {
                    path: 'assembleDimensions',
                    children: [
                        { path: '', component: (): Component => import('@/views/workflow/assembleDimensions/AssembleDimensions.vue') }
                        // { path: 'steps/:dimensionId?', component: (): Component => import('@/views/workflow/assembleDimensions/AssembleDimensionsSteps.vue') }
                    ]
                },
                {
                    path: 'contextualiseData',
                    children: [
                        { path: '', component: (): Component => import('@/views/workflow/contextualiseData/ContextualiseData.vue') }
                        // { path: 'steps/:eventQueryId?', component: (): Component => import('@/views/workflow/contextualiseData/ContextualiseDataSteps.vue') }
                    ]
                },
                { path: 'exploreResults', children: [{ path: '', component: (): Component => import('@/views/workflow/exploreResults/ExploreResults.vue') }] },
                { path: 'buildDataApps', children: [{ path: '', component: (): Component => import('@/views/workflow/buildDataApps/BuildDataApps.vue') }] },
                // { path: 'connections/:connectionId?', component: (): Component => import('@/views/workflow/connections/Connections.vue') },
                // { path: 'contexts/:contextId?', component: (): Component => import('@/views/workflow/contexts/Contexts.vue') }
                { path: 'manageComponents', component: (): Component => import('@/views/workflow/manageComponents/ManageComponents.vue') }
                // { path: 'contexts/:contextId/:areaId', component: (): Component => import('@/views/workflow/contexts/ContextArea.vue') }
            ]
        }
    ]
};
let workbenchMaskTimeout: number | undefined;

// Router - Create
const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [routes, { path: '/:catchAll(.*)*', redirect: '/' }],
    scrollBehavior: (to, from, savedPosition): { behavior?: ScrollOptions['behavior']; left: number; top: number } => savedPosition || { left: 0, top: 0 }
});
router.onError((error, to): void => {
    handleError(error, { locator: 'router.1', routerTo: to });
});
router.beforeEach((to): void => {
    const workbenchStore = useWorkbenchStore();
    if (!workbenchStore.isLoading) {
        workbenchMaskTimeout = window.setTimeout((): void => {
            workbenchStore.moduleIsLoading = true;
        }, TIMEOUT_DELAY);
    }
    trackPageViewEvent(to);
});
router.afterEach((): void => {
    window.clearTimeout(workbenchMaskTimeout);
    workbenchMaskTimeout = undefined;
    const workbenchStore = useWorkbenchStore();
    workbenchStore.clearMaskTimeout();
    workbenchStore.moduleIsLoading = false;
});

// Router - Expose
export default router;
