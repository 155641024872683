// Dependencies - Vendor
import { createApp } from 'vue';
import { createPinia } from 'pinia';

// Dependencies - Framework
import { handleError, initAppMonitor } from '@/globals';
import { initTranslations } from './locales';
import router from '@/router';

// Dependencies - Component
import App from '@/App.vue';

// Application - Load
try {
    // Create the main Vue instance using the root component and initialise global error handler.
    const app = createApp(App);
    app.config.errorHandler = (error: unknown, vm: unknown, info: string): boolean => {
        return handleError(error, { info, locator: 'main.errorHandler.1' }); // Report unhandled error.
    };

    // Register plugins.
    app.use(createPinia());
    app.use(router);

    // Initialize translations.
    initTranslations(app);

    // Asynchronously load and initialize the application monitor so we don't block the application mount.
    // The application monitor is also responsible for asynchronously loading the session manager.
    // We communicate with the application monitor via helper functions in 'src/global.ts'.
    import('@/appMonitor')
        .then((module): void => {
            try {
                initAppMonitor(module).catch((error): boolean => {
                    return handleError(error, { locator: 'main.import.appMonitor.then.initAppMonitor.1' }); // Report application monitor initialisation error.
                });
            } catch (error) {
                handleError(error, { locator: 'main.import.appMonitor.then.catch.1' }); // Report application monitor load error.
            }
        })
        .catch((error): boolean => handleError(error, { locator: 'main.import.appMonitor.catch.1' })); // Report application monitor import error.

    // Mount the application to the DOM. This will begin before the application monitor and session manager have completed loading.
    app.mount('#app');
} catch (error) {
    handleError(error, { locator: 'main.catch.1' }); // Report application load error.
}
