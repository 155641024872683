// Dependencies - Vendor
import { defineStore } from 'pinia';
import { ref } from 'vue';

// Internet Store - Compose & Expose
export const useInternetStore = defineStore('internet', () => {
    // Properties & Variables
    const internetConnectionIsOnline = ref<boolean | undefined>(undefined);

    // Exposures
    return {
        internetConnectionIsOnline
    };
});
