// Dependencies - Vendor
import { type App, ref, shallowRef, watchEffect } from 'vue';

// Dependencies - Framework
import { formatNumberAsDecimalNumber, formatNumberAsWholeNumber } from '@datapos/datapos-share-core';
import englishTranslations from '@/locales/en.json';

// Interfaces/Types - Component Custom Properties
declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $n(value: number, key?: string): string;
        $t(id: string, params?: Record<string, number | string>): string;
    }
}

// Constants
const SUPPORTED_LANGUAGE_IDS: string[] = ['en', 'es'];

// Variables
export const localeId = ref<string>('en');
const translations = shallowRef<Record<string, Record<string, string>>>(englishTranslations);

// Reactive Variables & Watchers
watchEffect(async () => {
    switch (localeId.value) {
        case 'es':
            translations.value = (await import('@/locales/es.json')).default;
            break;
        default:
            translations.value = englishTranslations;
            break;
    }
});

// Helpers - Initialise Translations
export const initTranslations = (app: App<Element>): void => {
    localeId.value = establishLocaleIdId();
    app.config.globalProperties.$n = n;
    app.config.globalProperties.$t = t;
};

// Helpers - Format Number
export const n = (value: number, key: string): string => {
    switch (localeId.value) {
        case 'es':
            switch (key) {
                case 'fixed1':
                    return formatNumberAsDecimalNumber(value, 1, 1, 'es');
                case 'fixed2':
                    return formatNumberAsDecimalNumber(value, 2, 2, 'es');
                default:
                    return formatNumberAsWholeNumber(value, 'es');
            }
        default:
            switch (key) {
                case 'fixed1':
                    return formatNumberAsDecimalNumber(value, 1, 1, 'en');
                case 'fixed2':
                    return formatNumberAsDecimalNumber(value, 2, 2, 'en');
                default:
                    return formatNumberAsWholeNumber(value, 'en');
            }
    }
};

// Helpers - Get String Translation
export const t = (id: string, params?: Record<string, number | string>): string => {
    const [topicId, stringId] = id.split('.');
    const text = translations.value?.[topicId]?.[stringId] ?? (localeId.value !== 'en' ? (englishTranslations as Record<string, Record<string, string>>)?.[topicId]?.[stringId] : undefined);
    return text ? interpolateParams(text, params) : `??:${id}`;
};

// Utilities - Establish Locale Identifier
const establishLocaleIdId = (): string => {
    // Check for exact language match.
    for (const languageId of window.navigator.languages) {
        const transformedLanguageId = languageId.toLowerCase();
        if (SUPPORTED_LANGUAGE_IDS.includes(transformedLanguageId)) {
            return transformedLanguageId;
        }
    }
    // Check for neutral language match.
    for (const languageId of window.navigator.languages) {
        const transformedLanguageId = languageId.split('-')[0].toLowerCase();
        if (SUPPORTED_LANGUAGE_IDS.includes(transformedLanguageId)) {
            return transformedLanguageId;
        }
    }
    return 'en'; // Default to english.
};

// Utilities - Interpolate Params
const interpolateParams = (text: string, params?: Record<string, number | string>): string => {
    return text.replace(/\{(\w+)\}|\{('.*?')\}/gu, (match, paramId, fixedString): string => {
        if (fixedString) {
            return fixedString.slice(1, -1); // Remove surrounding quotes and return fixed string.
        }
        return params && paramId in params ? String(params[paramId]) : paramId; // Return parameter value or parameter identifier if not found.
    });
};
