// Dependencies - Framework
import type {
    GetMonitorIssuesResult,
    GetMonitorWeeklyTimingsResult,
    IPAddressLocation,
    LocationTimings,
    MonitorIssue,
    MonitorIssuesByWeek,
    MonitorTimingsByLocation,
    MonitorTimingsByWeek
} from '@datapos/datapos-share-core';
import { type Query, useQuery } from '@/queries/useQuery';

// Constants
export const API_ENDPOINTS = {
    OPERATIONS: {
        CONFIG: 'https://operations.datapositioning.app/config',
        CONNECTORS: 'https://operations.datapositioning.app/connectors',
        LOCATION: 'https://operations.datapositioning.app/location',
        MONITORS: 'https://operations.datapositioning.app/monitors',
        PACKAGES: 'https://operations.datapositioning.app/packages',
        SERVICES: {
            WEBSOCKET: 'wss://operations.datapositioning.app/services/websocket'
        },
        TOKEN: 'https://operations.datapositioning.app/token'
    },
    MESSENGER: {
        CONFIG: 'https://messenger.datapositioning.app/config',
        ROOMS: 'wss://messenger.datapositioning.app/rooms'
    },
    RESOURCES: {
        CONFIG: 'https://resources.datapositioning.app/config'
    },
    WORKBENCH: {
        INSTANCE: '/instance'
    }
};

// Helpers - Use Get Location
export const useGetLocation = (ipAddress: string): Query<IPAddressLocation> => useQuery<IPAddressLocation>({ url: API_ENDPOINTS.OPERATIONS.LOCATION, headers: { 'Current-IP-Address': ipAddress } });

// Helpers - Use Get Monitor Issues
export const useGetMonitorIssues = (monitorId: string): Query<GetMonitorIssuesResult> => useQuery<GetMonitorIssuesResult>({ url: `${API_ENDPOINTS.OPERATIONS.MONITORS}/${monitorId}/issues` });

// Helpers - Use Get Monitor Weekly Timings
export const useGetMonitorWeeklyTimings = (monitorId: string): Query<GetMonitorWeeklyTimingsResult> =>
    useQuery<GetMonitorWeeklyTimingsResult>({ url: `${API_ENDPOINTS.OPERATIONS.MONITORS}/${monitorId}/timings` });

// Helpers - Summarise Issues by Week
export const summariseIssuesByWeek = (issues: MonitorIssue[]): MonitorIssuesByWeek[] => {
    // TODO: Also returns total issue durations by week for the last 14 weeks (Mon-Sun).
    const now = new Date();
    const dayOfWeek = now.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday.
    const daysUntilSunday = (7 - dayOfWeek) % 7; // 0 if today is Sunday, or days until next Sunday.
    const nextSunday = new Date(now);
    nextSunday.setHours(23, 59, 59, 999);
    nextSunday.setDate(now.getDate() + daysUntilSunday);
    const nextSundayTimestamp = nextSunday.getTime();
    const issuesByWeek: MonitorIssuesByWeek[] = [];
    for (let weekCount = 13; weekCount >= 0; weekCount--) {
        const toTimestamp = nextSundayTimestamp - 7 * 24 * 60 * 60 * 1000 * weekCount;
        const fromTimestamp = toTimestamp - 7 * 24 * 60 * 60 * 1000 + 1;
        issuesByWeek.push({
            weekNumber: Math.abs(weekCount - 13) + 1,
            from: new Date(fromTimestamp).toISOString(),
            fromTimestamp,
            to: new Date(toTimestamp).toISOString(),
            toTimestamp,
            totalDurationSeconds: 0
        });
    }
    for (const issue of issues) {
        for (const week of issuesByWeek) {
            if (issue.fromTimestamp <= week.toTimestamp && issue.toTimestamp >= week.fromTimestamp) {
                const proRataStartStamp = issue.fromTimestamp < week.fromTimestamp ? week.fromTimestamp : issue.fromTimestamp;
                const proRataEndStamp = issue.toTimestamp > week.toTimestamp ? week.toTimestamp : issue.toTimestamp;
                const proRataAdjustment = (proRataEndStamp - proRataStartStamp) / (issue.toTimestamp - issue.fromTimestamp);
                week.totalDurationSeconds += issue.durationSeconds * proRataAdjustment;
            }
        }
    }
    return issuesByWeek;
};

// Helpers - Summarise Timings by Week
export const summariseTimingsByWeek = (weekIds: string[], weekValues: (LocationTimings | null)[]): MonitorTimingsByWeek[] => {
    const monitorTimingsByWeek: MonitorTimingsByWeek[] = [];
    for (const [weekIndex, weekId] of weekIds.entries()) {
        const weekValue = weekValues[weekIndex];
        if (weekValue) {
            const timingsByLocation: MonitorTimingsByLocation[] = [];
            let totalCount = 0;
            let totalDurationSeconds = 0;
            for (const locationTimings of Object.entries(weekValue)) {
                const locationId = locationTimings[0];
                // const location = (locationsById as LocationsById)[locationId];
                // if (location) {
                const count = locationTimings[1][0];
                totalCount += count;
                const duration = locationTimings[1][1];
                totalDurationSeconds += duration;
                timingsByLocation.push({ locationId, continentLabel: '', count, countryLabel: '', locationLabel: '', totalDurationSeconds: duration });
                // }
            }
            monitorTimingsByWeek.push({ weekId: weekId.substring(2), count: totalCount, totalDurationSeconds, byLocation: timingsByLocation });
        }
    }
    return monitorTimingsByWeek;
};
