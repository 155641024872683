// Dependencies - Vendor Types
import type { AsyncComponentOptions } from 'vue';
import type { RouteLocationNormalizedGeneric } from 'vue-router';

// Dependencies - Framework Types
import type { ErrorContext } from '@datapos/datapos-share-core';

// Dependencies - Framework Instances
import { t } from '@/locales';

// Dependencies - Component Instances
import LoadingMask from '@/components/LoadingMask.vue';
import UnavailableMask from '@/components/UnavailableMask.vue';

// Interfaces/Types - Component Type Configuration
export type ComponentTypeConfig = Record<string, { base: string; variants: Record<string, string> }>;

// Interfaces/Types - Dependency
export interface Dependency {
    name: string;
    repo: string;
    version: string;
}

// Interfaces/Types - Scroller Item
export interface ScrollerItem extends Record<string, boolean | number | string | undefined> {
    id: string;
    label: string;
    rowHeight?: number;
}

// Interfaces/Types - Numeric Scale
export type NumericScale = [number, string];

// Constants
export const DEFAULT_DEBOUNCE_DELAY = 300;
export const DEFINE_ASYNC_COMPONENT_DELAY = 200;
export const DEFINE_ASYNC_COMPONENT_TIMEOUT = 5000;
export const DEFINE_ASYNC_COMPONENT_OPTIONS: Partial<AsyncComponentOptions> = {
    errorComponent: UnavailableMask,
    loadingComponent: LoadingMask,
    timeout: DEFINE_ASYNC_COMPONENT_TIMEOUT,
    onError(error, retry, fail, attempts): void {
        console.log('defineAsyncComponent.onError', error, retry, fail, attempts);
    }
};
export const LOCAL_STORAGE = {
    APPEARANCE: {
        ID: 'datapos-appearance-id'
    },
    LOCATION: {
        DATA: 'datapos-location-data'
    },
    OPTION_DRAWER: {
        WIDTH_ID: 'datapos-option-drawer-width-id'
    },
    TOOL_DRAWER: {
        PANEL_ID: 'datapos-tool-drawer-panel-id',
        STATE_ID: 'datapos-tool-drawer-state-id',
        WIDTH_ID: 'datapos-tool-drawer-width-id'
    }
};
export const SECONDS_IN_A_WEEK = 7 * 24 * 60 * 60;
export const SKELETON_DISPLAY_DELAY = 250;
const RELOAD_TIMESTAMP_PARAM = 'reload-timestamp';
export const TIMEOUT_MS = 5000;
export const VALUE_HUNDRED = 100;
export const VALUE_BILLION = 1000000000;
export const VALUE_MILLION = 1000000;
export const VALUE_THOUSAND = 1000;

// Variables
let appMonitor: typeof import('@/appMonitor') | undefined;

// APPLICATION MONITOR Helpers - Handle Error
export const handleError = (error?: unknown, context?: ErrorContext, logOnly?: boolean): boolean => {
    // If application monitor loaded then use it to report error and exit.
    if (appMonitor) {
        return appMonitor.reportError(error, context, logOnly);
    }

    // If application monitor NOT loaded then report error using alert box.
    const contextClause = `Context: ${context?.locator ?? 'unknown'}`;
    if (error instanceof Error) {
        alert(`${error.message} ${contextClause}`);
        return false;
    }
    alert(`${String(error || t('alert.unknownErrorMessage'))} ${contextClause}`);
    return false;
};

// APPLICATION MONITOR Helpers - Initialise
export const initAppMonitor = async (module: typeof import('@/appMonitor')): Promise<void> => {
    await module.initialise();
    appMonitor = module;
};

// APPLICATION MONITOR Helpers - Track Page View Event
export const trackPageViewEvent = (to: RouteLocationNormalizedGeneric): void => {
    appMonitor?.trackPageViewEvent(to);
};

// ERROR Helpers - Show Error Using Browser Alert Box
export const showErrorUsingBrowserAlertBox = (error: unknown): void => {
    let errorMessage = error ? (error as Error).message : String(error);
    if (errorMessage && !errorMessage.endsWith('.')) {
        errorMessage += '.';
    }
    alert(t('alert.failedToInitialiseAppMonitorMessage', { errorMessage: errorMessage ? ` ${errorMessage}` : '' }));
};

// SCALE Helpers - Determine Storage Scale
export const determineStorageScale = (number?: number): NumericScale => {
    if (!number) return [0, t('snipet.Byte')];
    if (number === 1) return [1, t('snipet.Byte')];
    if (number < 1024) return [number, t('snipet.Byte')];
    if (number < 1048576) return [number / 1024, t('snipet.Kilobyte')];
    if (number < 1073741824) return [number / 1048576, t('snipet.Megabyte')];
    if (number < 1099511627776) return [number / 1073741824, t('snipet.Gigabyte')];
    return [number / 1099511627776, t('snipet.Terabyte')];
};

// STRING Helpers - Build Component Classes
export const buildComponentClasses = (typeConfigs: ComponentTypeConfig, typePath: string): string => {
    const typePathSegments = typePath.split('.');
    const [typeId, variantId = ''] = typePathSegments;
    const typeConfig = typeConfigs[typeId];
    return `${typeConfig.base} ${typeConfig.variants[variantId]}`;
};

// UI Helpers - Navigate to External Link
export const navigateToExternalLink = (url?: string): void => {
    if (url) {
        window.open(url, '_blank', 'noopener noreferrer');
    }
};

// UI Helpers - Reload Workbench
export const reloadWorkbench = (): void => {
    // Reload the workbench by appending a timestamp to the URL and forcing a reload of the page.
    const appURL = new URL(window.location.href);
    const params = appURL.searchParams;
    params.delete(RELOAD_TIMESTAMP_PARAM);
    params.set(RELOAD_TIMESTAMP_PARAM, String(new Date().getTime()));
    window.location.href = appURL.toString();
};
