// Dependencies - Framework
import { API_ENDPOINTS } from '@/queries';
import { reloadWorkbench } from '@/globals';
import { t } from '@/locales';
import { useAlertStore } from '@/stores/alertStore';
import { useWorkbenchStore } from '@/stores/workbenchStore';
import { version } from '@/config.json';

// Constants
const TIMEOUT_DELAY = 5000;

// Non-Reactive Variables
let servicesWebSocket: WebSocket | undefined;

// Utilities - Compare Version Strings
const compareVersionStrings = (left: string, right: string): number => {
    const leftSegments = left.split('.').map(Number);
    const rightSegments = right.split('.').map(Number);
    for (let index = 0; index < Math.max(leftSegments.length, rightSegments.length); index++) {
        const leftSegment = leftSegments[index] || 0;
        const rightSegment = rightSegments[index] || 0;
        if (leftSegment > rightSegment) {
            return 1;
        }
        if (leftSegment < rightSegment) {
            return -1;
        }
    }
    return 0;
};

// Utilities - Connect Services WebSocket
export const connectToWebSocket = (): WebSocket | undefined => {
    try {
        const workbenchStore = useWorkbenchStore();
        const wsURL = API_ENDPOINTS.OPERATIONS.SERVICES.WEBSOCKET;
        console.log(`Attempting to connect to WebSocket: ${wsURL}...`);
        servicesWebSocket = new WebSocket(wsURL);

        servicesWebSocket.onopen = (): void => console.log('WebSocket connection established.');

        servicesWebSocket.onmessage = (event): void => {
            try {
                const data = JSON.parse(event.data);
                const workbenchSession = data.find((session: Record<string, unknown>): boolean => session.id === `workbench-${workbenchStore.isEUInstance ? 'eu' : 'global'}`);
                if (!workbenchSession) {
                    return;
                }
                const newVersion = workbenchSession.data.version;
                if (compareVersionStrings(workbenchStore.lastDeployedVersion || version, newVersion) === -1) {
                    workbenchStore.lastDeployedVersion = newVersion;
                    // If installed version, or last seen version, is less than new version then show install alert.
                    useAlertStore().showAlert({
                        actionLabel: t('action.reload.label'),
                        actionCallback: (): void => reloadWorkbench(),
                        kind: 'default.neutral',
                        message: t('alert.newVersionAvailableMessage', { version: newVersion }),
                        notes: t('alert.newVersionAvailableNotes', { version })
                    });
                }
            } catch (error) {
                console.log('Error parsing message.', error);
            }
        };

        servicesWebSocket.onclose = (event): void => {
            console.log(`WebSocket closed. Code: ${event.code}.`);
            servicesWebSocket = undefined;
            setTimeout(connectToWebSocket, TIMEOUT_DELAY);
        };

        servicesWebSocket.onerror = (error): void => {
            // TODO: Try and reconnect a limited number of times.
            console.log('Error occurred. Check error log for details.', error);
        };

        return servicesWebSocket;
    } catch (error) {
        console.log('Failed to create WebSocket. Check error log for details.', error);
        return undefined;
    }
};
